
import { Vue, Component, Watch } from 'vue-property-decorator'
import MapView from './components/MapView.vue'
import menuList from './components/menu'
import Seedling from './resource/Seedling.vue'
import FamousTree from './resource/FamousTree.vue'
import Building from './resource/Building.vue'
import Service from './resource/Service.vue'
import Scenery from './resource/Scenery.vue'
import Message from './Message.vue'
import { MapPointDetail } from '@/types/workbenchResource'

@Component({
  components: {
    MapView,
    Seedling,
    FamousTree,
    Building,
    Service,
    Scenery,
    Message
  }
})
export default class Index extends Vue {
  private contentMenuList: Array<Array<any>> = menuList
  private headMenuIndex = 0
  private contentMenuIndex = 0
  private projectList: Array<any> = []
  private currentProjectIndex = 0

  created (): void {
    this.getProjectList()
  }

  get projectId () {
    return this.projectList[this.currentProjectIndex] && this.projectList[this.currentProjectIndex].projectId
      ? this.projectList[this.currentProjectIndex].projectId : ''
  }

  get projectName () {
    return this.projectList[this.currentProjectIndex] && this.projectList[this.currentProjectIndex].projectName
      ? this.projectList[this.currentProjectIndex].projectName : ''
  }

  get pageName () {
    let url = ''
    if (this.contentMenuList[this.headMenuIndex] && this.contentMenuList[this.headMenuIndex][this.contentMenuIndex] && this.contentMenuList[this.headMenuIndex][this.contentMenuIndex].url) {
      url = this.contentMenuList[this.headMenuIndex][this.contentMenuIndex].url
    }
    return url
  }

  @Watch('pageName')
  resetMapView () {
    ;(this.$refs.mapView as any).removeAllMarker()
  }

  getProjectList () {
    this.$axios.get(this.$apis.project.selectProjectByList).then((res) => {
      this.projectList = res.list || []
      if (this.projectList.length > 0) {
        this.$nextTick(() => {
          setTimeout(() => {
            ;(this.$refs.mapView as any).setMapCenter(0, true)
          }, 0)
        })
      }
    })
  }

  projectChange (index: number): void {
    this.currentProjectIndex = index
    this.resetMapView()
    this.getProjectList()
  }

  // 地图打点 type为正常状态下打点图表的类型
  drawMarkers (arr: Array<{ type: string; list: Array<MapPointDetail> }>) {
    ;(this.$refs.mapView as any).drawMarkers(arr)
  }

  // 选中高亮 list为选中的id数组对象, 如果list为空数组，则表示去掉所有高亮点
  setMarkersSelect (list: Array<{ id: string }>) {
    ;(this.$refs.mapView as any).setMarkersSelect(list)
  }

  contentMenuClick (cIndex: number): void {
    this.contentMenuIndex = cIndex
    // ....do
  }
}
