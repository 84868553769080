
import { Vue, Component } from 'vue-property-decorator'
import Tianrenshanshui from './tianrenshanshui/Index.vue'
import ResetPassword from '@/components/resetPassword/Index.vue'

@Component({
  components: {
    Tianrenshanshui,
    ResetPassword
  }
})
export default class Workbench extends Vue {
  get customerCode () {
    return sessionStorage.getItem('customerCode') || ''
  }
}
