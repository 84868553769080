
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class Message extends Vue {
  @Prop() readonly projectId!: string

  private dialogVisible = false
  private size = 10
  private messageList = []
  private loading = false
  private websocket: any = ''

  get messageNum () {
    return this.$store.state.messageNum
  }

  @Watch('projectId')
  projectIdChange () {
    this.$store.dispatch('getMessageNum', { projectId: this.projectId })
  }

  destroyed () {
    // 离开之后断开websocket连接
    if (this.websocket) {
      this.websocket.close()
    }
  }

  created () {
    console.log(this.projectId)
    this.$store.dispatch('getMessageNum', { projectId: this.projectId })
    this.initWebSocket()
  }

  getData () {
    if (this.loading === false) {
      this.loading = true
      this.$axios.get(this.$apis.messageWarning.warningPage, {
        page: 1,
        size: this.size,
        readState: '0',
        projectId: this.projectId
      }).then((res) => {
        this.messageList = res.list || []
      }).finally(() => {
        this.loading = false
      })
    }
  }

  scroll (e: any) {
    if (e.srcElement.scrollTop + e.srcElement.clientHeight >= e.srcElement.scrollHeight) {
      this.size += 10
      this.getData()
    }
  }

  // 打开
  open () {
    this.size = 10
    this.dialogVisible = true
    this.getData()
  }

  // 弹窗关闭
  beforeClose () {
    this.messageNum !== 0 && this.onRead()
    this.dialogVisible = false
  }

  // 全部已读
  onRead () {
    this.$axios.post(this.$apis.messageWarning.readAllMsg, { projectId: this.projectId }).then(() => {
      this.messageList = []
      this.$store.dispatch('getMessageNum', { projectId: this.projectId })
    })
  }

  // 长链接
  initWebSocket () {
    const userId = JSON.parse(window.sessionStorage.getItem('userInfo') as any).userId
    if (userId) {
      const url = process.env.VUE_APP_WEBSOCKET + '?userId=' + userId
      this.websocket = new WebSocket(url)
      this.websocket.onmessage = this.websocketonmessage
      this.websocket.onerror = this.websocketonerror
      this.websocket.onclose = () => {
        console.log('websocket断开')
      }
    }
  }

  // 消息接收
  websocketonmessage () {
    this.$store.dispatch('getMessageNum', { projectId: this.projectId })
  }

  // 链接失败
  websocketonerror () {
    this.initWebSocket()
  }
}
