/** 普通苗木 */
const seedling = require('@/assets/icon/workbench/icon_map_putongmiaomu.svg')
const seedlingSelect = require('@/assets/icon/workbench/icon_map_putongmiaomu_select.svg')
/** 古树名木 */
const famousTree = require('@/assets/icon/workbench/icon_map_gushumingmu.svg')
const famousTreeSelect = require('@/assets/icon/workbench/icon_map_gushumingmu_select.svg')
/** 建筑设施 */
const building = require('@/assets/icon/workbench/icon_map_jianzhusheshi.svg')
const buildingSelect = require('@/assets/icon/workbench/icon_map_jianzhusheshi_select.svg')
/** 服务设施 */
const service = require('@/assets/icon/workbench/icon_map_fuwusheshi.svg')
const serviceSelect = require('@/assets/icon/workbench/icon_map_fuwusheshi_select.svg')
/** 景观景点 */
const scenery = require('@/assets/icon/workbench/icon_map_jingguanjingdian.svg')
const scenerySelect = require('@/assets/icon/workbench/icon_map_jingguanjingdian_select.svg')
/** 智能设备、智能机械、所有设备及各种状态图表 */
// 工人，手环
const workerNormal = require('@/assets/icon/workbench/icon_map_shouhuan.svg')
const workerNormalSelect = require('@/assets/icon/workbench/icon_map_shouhuan_select.svg')
const workerOffline = require('@/assets/icon/workbench/icon_map_shouhuan_lixian.svg')
const workerOfflineSelect = require('@/assets/icon/workbench/icon_map_shouhuan_lixian_select.svg')
const workerSos = require('@/assets/icon/workbench/icon_map_shouhuan_sos.svg')
const workerSosSelect = require('@/assets/icon/workbench/icon_map_shouhuan_sos_select.svg')
const workerAbnormal = require('@/assets/icon/workbench/icon_map_shouhuan_warning.svg')
const workerAbnormalSelect = require('@/assets/icon/workbench/icon_map_shouhuan_warning_select.svg')
// 水质
const waterQuality = require('@/assets/icon/workbench/icon_map_shuizhijiance.svg')
const waterQualitySelect = require('@/assets/icon/workbench/icon_map_shuizhijiance_select.svg')
const waterQualityOff = require('@/assets/icon/workbench/icon_map_shuizhijiance_lixian.svg')
const waterQualityOffSelect = require('@/assets/icon/workbench/icon_map_shuizhijiance_lixian_select.svg')
// 车辆
const vehicleOnline = require('@/assets/icon/workbench/icon_map_chezaidingwei.svg')
const vehicleOnlineSelect = require('@/assets/icon/workbench/icon_map_chezaidingwei_select.svg')
const vehicleOffline = require('@/assets/icon/workbench/icon_map_chezaidingwei_lixian.svg')
const vehicleOfflineSelect = require('@/assets/icon/workbench/icon_map_chezaidingwei_lixian_select.svg')
// 温湿
const humidityNormal = require('@/assets/icon/workbench/icon_map_wenshi.svg')
const humidityNormalSelect = require('@/assets/icon/workbench/icon_map_wenshi_select.svg')
const humidityOffline = require('@/assets/icon/workbench/icon_map_wenshi_lixian.svg')
const humidityOfflineSelect = require('@/assets/icon/workbench/icon_map_wenshi_lixian_select.svg')
const humidityAbnormal = require('@/assets/icon/workbench/icon_map_wenshi_warning.svg')
const humidityAbnormalSelect = require('@/assets/icon/workbench/icon_map_wenshi_warning_select.svg')
// 肥力
const fertilityNormal = require('@/assets/icon/workbench/icon_map_feili.svg')
const fertilityNormalSelect = require('@/assets/icon/workbench/icon_map_feili_select.svg')
const fertilityOffline = require('@/assets/icon/workbench/icon_map_feili_lixian.svg')
const fertilityOfflineSelect = require('@/assets/icon/workbench/icon_map_feili_lixian_select.svg')
const fertilityAbnormal = require('@/assets/icon/workbench/icon_map_feili_warning.svg')
const fertilityAbnormalSelect = require('@/assets/icon/workbench/icon_map_feili_warning_select.svg')
// 虫情
const insectOnline = require('@/assets/icon/workbench/icon_map_zhihuichongqing.svg')
const insectOnlineSelect = require('@/assets/icon/workbench/icon_map_zhihuichongqing_select.svg')
const insectOffline = require('@/assets/icon/workbench/icon_map_zhihuichongqing_lixian.svg')
const insectOfflineSelect = require('@/assets/icon/workbench/icon_map_zhihuichongqing_lixian_select.svg')
// 安防，监控
const security = require('@/assets/icon/workbench/icon_map_zhihuijiankong.svg')
const securitySelect = require('@/assets/icon/workbench/icon_map_zhihuijiankong_select.svg')
const securityOff = require('@/assets/icon/workbench/icon_map_zhihuijiankong_lixian.svg')
const securityOffSelect = require('@/assets/icon/workbench/icon_map_zhihuijiankong_lixian_select.svg')
// 水量、水表
const waterMonitor = require('@/assets/icon/workbench/icon_map_zhihuishuibiao.svg')
const waterMonitorSelect = require('@/assets/icon/workbench/icon_map_zhihuishuibiao_select.svg')
const waterMonitorOff = require('@/assets/icon/workbench/icon_map_zhihuishuibiao_lixian.svg')
const waterMonitorOffSelect = require('@/assets/icon/workbench/icon_map_zhihuishuibiao_lixian_select.svg')
// 电量、电表、断路器
const electricMonitor = require('@/assets/icon/workbench/icon_map_zhihuiduanluqi.svg')
const electricMonitorSelect = require('@/assets/icon/workbench/icon_map_zhihuiduanluqi_select.svg')
const electricMonitorOff = require('@/assets/icon/workbench/icon_map_zhihuiduanluqi_lixian.svg')
const electricMonitorOffSelect = require('@/assets/icon/workbench/icon_map_zhihuiduanluqi_lixian_select.svg')
// 电磁阀
const valve = require('@/assets/icon/workbench/icon_map_diancifa_close.svg')
const valveSelect = require('@/assets/icon/workbench/icon_map_diancifa_close_select.svg')
const valveOff = require('@/assets/icon/workbench/icon_map_diancifa_close.svg')
const valveOffSelect = require('@/assets/icon/workbench/icon_map_diancifa_close_select.svg')
const valveOpen = require('@/assets/icon/workbench/icon_map_diancifa_open.svg')
const valveOpenSelect = require('@/assets/icon/workbench/icon_map_diancifa_open_select.svg')
// 烟感
const smoke = require('@/assets/icon/workbench/icon_map_zhihuiyangan.svg')
const smokeSelect = require('@/assets/icon/workbench/icon_map_zhihuiyangan_select.svg')
// 垃圾桶
const trashcanOnline = require('@/assets/icon/workbench/icon_map_laji.svg')
const trashcanOnlineSelect = require('@/assets/icon/workbench/icon_map_laji_select.svg')
const trashcanOffline = require('@/assets/icon/workbench/icon_map_laji_lixian.svg')
const trashcanOfflineSelect = require('@/assets/icon/workbench/icon_map_laji_lixian_select.svg')
const trashcanAbnormal = require('@/assets/icon/workbench/icon_map_laji_warning.svg')
const trashcanAbnormalSelect = require('@/assets/icon/workbench/icon_map_laji_warning_select.svg')
// 空气质量，环境监测
const weather = require('@/assets/icon/workbench/icon_map_huanjingjiankong.svg')
const weatherSelect = require('@/assets/icon/workbench/icon_map_huanjingjiankong_select.svg')

const iconList = [
  {
    type: 'common',
    icon: seedling
  }, {
    type: 'seedling',
    icon: seedling
  }, {
    type: 'seedlingSelect',
    icon: seedlingSelect
  }, {
    type: 'famousTree',
    icon: famousTree
  }, {
    type: 'famousTreeSelect',
    icon: famousTreeSelect
  }, {
    type: 'building',
    icon: building
  }, {
    type: 'buildingSelect',
    icon: buildingSelect
  }, {
    type: 'service',
    icon: service
  }, {
    type: 'serviceSelect',
    icon: serviceSelect
  }, {
    type: 'scenery',
    icon: scenery
  }, {
    type: 'scenerySelect',
    icon: scenerySelect
  }, {
    type: 'workerOffline', // 工人离线
    icon: workerOffline
  }, {
    type: 'workerSos', // 工人sos
    icon: workerSos
  }, {
    type: 'workerNormal', // 工人正常
    icon: workerNormal
  }, {
    type: 'workerAbnormal', // 工人异常
    icon: workerAbnormal
  }, {
    type: 'workerOfflineSelect', // 工人离线选中
    icon: workerOfflineSelect
  }, {
    type: 'workerSosSelect', // 工人sos选中
    icon: workerSosSelect
  }, {
    type: 'workerNormalSelect', // 工人正常选中
    icon: workerNormalSelect
  }, {
    type: 'workerAbnormalSelect', // 工人异常选中
    icon: workerAbnormalSelect
  }, {
    type: 'waterQuality', // 水质正常
    icon: waterQuality
  }, {
    type: 'waterQualitySelect', // 水质选中
    icon: waterQualitySelect
  }, {
    type: 'waterQualityOff', // 水质离线
    icon: waterQualityOff
  }, {
    type: 'waterQualityOffSelect', // 水质离线选中
    icon: waterQualityOffSelect
  }, {
    type: 'vehicleOnline', // 车辆在线
    icon: vehicleOnline
  }, {
    type: 'vehicleOffline', // 车辆离线
    icon: vehicleOffline
  }, {
    type: 'vehicleOnlineSelect', // 车辆在线选中
    icon: vehicleOnlineSelect
  }, {
    type: 'vehicleOfflineSelect', // 车辆离线选中
    icon: vehicleOfflineSelect
  }, {
    type: 'humidityOffline', // 温湿离线
    icon: humidityOffline
  }, {
    type: 'humidityNormal', // 温湿正常
    icon: humidityNormal
  }, {
    type: 'humidityAbnormal', // 温湿异常
    icon: humidityAbnormal
  }, {
    type: 'humidityOfflineSelect', // 温湿离线选中
    icon: humidityOfflineSelect
  }, {
    type: 'humidityNormalSelect', // 温湿正常选中
    icon: humidityNormalSelect
  }, {
    type: 'humidityAbnormalSelect', // 温湿异常选中
    icon: humidityAbnormalSelect
  }, {
    type: 'fertilityOffline', // 肥力离线
    icon: fertilityOffline
  }, {
    type: 'fertilityNormal', // 肥力正常
    icon: fertilityNormal
  }, {
    type: 'fertilityAbnormal', // 肥力异常
    icon: fertilityAbnormal
  }, {
    type: 'fertilityOfflineSelect', // 肥力离线选中
    icon: fertilityOfflineSelect
  }, {
    type: 'fertilityNormalSelect', // 肥力正常选中
    icon: fertilityNormalSelect
  }, {
    type: 'fertilityAbnormalSelect', // 肥力异常选中
    icon: fertilityAbnormalSelect
  }, {
    type: 'insectOnline', // 虫情在线
    icon: insectOnline
  }, {
    type: 'insectOffline', // 虫情离线
    icon: insectOffline
  }, {
    type: 'insectOnlineSelect', // 虫情在线选中
    icon: insectOnlineSelect
  }, {
    type: 'insectOfflineSelect', // 虫情离线选中
    icon: insectOfflineSelect
  }, {
    type: 'security', // 智慧安防在线
    icon: security
  }, {
    type: 'securitySelect', // 智慧安防在线选中
    icon: securitySelect
  }, {
    type: 'securityOff', // 智慧安防离线
    icon: securityOff
  }, {
    type: 'securityOffSelect', // 智慧安防离线选中
    icon: securityOffSelect
  }, {
    type: 'waterMonitor', // 水量监测在线
    icon: waterMonitor
  }, {
    type: 'waterMonitorSelect', // 水量监测在线选中
    icon: waterMonitorSelect
  }, {
    type: 'waterMonitorOff', // 水量监测离线
    icon: waterMonitorOff
  }, {
    type: 'waterMonitorOffSelect', // 水量监测离线选中
    icon: waterMonitorOffSelect
  }, {
    type: 'electricMonitor', // 电量监测在线
    icon: electricMonitor
  }, {
    type: 'electricMonitorSelect', // 电量监测选中在线选中
    icon: electricMonitorSelect
  }, {
    type: 'electricMonitorOff', // 电量监测离线
    icon: electricMonitorOff
  }, {
    type: 'electricMonitorOffSelect', // 电量监测选中离线选中
    icon: electricMonitorOffSelect
  }, {
    type: 'valve', // 电磁阀
    icon: valve
  }, {
    type: 'valveSelect', // 电磁阀选中
    icon: valveSelect
  }, {
    type: 'valveOff', // 电磁阀离线
    icon: valveOff
  }, {
    type: 'valveOffSelect', // 电磁阀离线选中
    icon: valveOffSelect
  }, {
    type: 'valveOpen', // 电磁阀打开
    icon: valveOpen
  }, {
    type: 'valveOpenSelect', // 电磁阀打开选中
    icon: valveOpenSelect
  }, {
    type: 'smoke', // 烟感
    icon: smoke
  }, {
    type: 'smokeSelect', // 烟感选中
    icon: smokeSelect
  }, {
    type: 'trashcanOnline', // 垃圾桶在线
    icon: trashcanOnline
  }, {
    type: 'trashcanOnlineSelect', // 垃圾桶在线选中
    icon: trashcanOnlineSelect
  }, {
    type: 'trashcanOffline', // 垃圾桶离线
    icon: trashcanOffline
  }, {
    type: 'trashcanOfflineSelect', // 垃圾桶离线选中
    icon: trashcanOfflineSelect
  }, {
    type: 'trashcanAbnormal', // 垃圾桶异常
    icon: trashcanAbnormal
  }, {
    type: 'trashcanAbnormalSelect', // 垃圾桶异常选中
    icon: trashcanAbnormalSelect
  }, {
    type: 'weather', // 空气质量，环境监测
    icon: weather
  }, {
    type: 'weatherSelect', // 空气质量，环境监测选中
    icon: weatherSelect
  }
]

export default iconList
