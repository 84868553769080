
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import LeftModal from '../components/LeftModal.vue'
import RightModal from '../components/RightModal.vue'
import { SceneryDetail, RecordDetail, SceneryListDetail, MapPointDetail } from '@/types/workbenchResource.d'

@Component({
  components: {
    LeftModal,
    RightModal
  },
  filters: {
    backgroundFormat (value: { filePrefix: string; fileUrl: string }) {
      const url = (value.filePrefix || '') + (value.fileUrl || '')
      return url ? 'background: url(' + url + ') no-repeat center center /cover' : ''
    }
  }
})
export default class Scenery extends Vue {
  @Prop() readonly projectId!: string

  rightVisible = false
  currentIndex = -1
  currentId = ''
  detail: SceneryDetail | {} = {}
  page = 1
  size = 10
  isLoading = false
  isFinished = false
  list: Array<SceneryListDetail> = []
  amount = 0
  tabName = 'first'
  recordPage = 1
  recordSize = 20
  recordList: Array<RecordDetail> = []
  recordLoading = false
  recordFinished = false

  @Watch('projectId', { immediate: true })
  loadData (projectId: string) {
    if (projectId) {
      this.list = []
      this.page = 1
      this.rightVisible = false
      this.resetData()
      // 设置为自动加载了，所以无需这里再调用
      // this.loadMenuList()
      this.loadMapList()
    }
  }

  created (): void {
    this.$event.$on('markerClick', this.clickMaker)// marker点击事件
    this.$event.$on('mapClick', this.clickMap)// 地图点击事件
  }

  destroyed (): void {
    this.$event.$off('markerClick', this.clickMaker)// marker点击事件
    this.$event.$off('mapClick', this.clickMap)// 地图点击事件
  }

  resetData () {
    this.currentIndex = -1
    this.tabName = 'first'
    this.recordList = []
    this.recordPage = 1
  }

  clickMap () {
    this.rightVisible = false
    this.resetData()
    this.$emit('setMarkersSelect', [])
  }

  clickMaker (id: string) {
    this.resetData()
    this.currentId = id
    this.loadDetail()
  }

  // 获取左侧菜单列表
  loadMenuList () {
    this.isLoading = true
    const url = this.$apis.scenery.selectSceneryPage
    this.$axios.get<{ amount: number; total: number; list: Array<SceneryListDetail> }>(url, {
      projectId: this.projectId,
      page: this.page,
      size: this.size
    }).then(res => {
      this.page++
      this.amount = res.amount || 0
      this.list = res && res.list.length > 0 ? this.list.concat(res.list) : this.list
      this.isFinished = this.list.length === res.total
    }).catch(() => {
      this.isFinished = true
    }).finally(() => {
      this.isLoading = false
    })
  }

  // 获取左侧菜单列表
  loadMapList () {
    this.$axios.get<Array<MapPointDetail>>(this.$apis.scenery.selectSceneryList, {
      projectId: this.projectId
    }).then(res => {
      this.drawMarkers(res || [])
    })
  }

  // 添加坐标
  drawMarkers (list: Array<MapPointDetail>) {
    if (list.length > 0) {
      this.$emit('drawMarkers', [{ type: 'scenery', list: list }])
    }
  }

  // 加载详情
  loadDetail () {
    this.$axios.get<{ plantId: string }>(this.$apis.scenery.selectSceneryBySceneryId, {
      sceneryId: this.currentId
    }).then(res => {
      this.detail = res
      this.rightVisible = true
    })
  }

  // 加载养护信息
  loadRecord () {
    this.recordLoading = true
    this.$axios.get<{ total: number; list: Array<RecordDetail> }>(this.$apis.record.selectMaintainRecordByPage, {
      objectId: this.currentId,
      page: this.recordPage,
      size: this.recordSize
    }).then(res => {
      this.recordPage++
      this.recordList = res && res.list.length > 0 ? this.recordList.concat(res.list) : this.recordList
      this.recordFinished = this.recordList.length === res.total
    }).catch(() => {
      this.recordFinished = true
    }).finally(() => {
      this.recordLoading = false
    })
  }

  // 菜单切换
  changeChoose (index: number): void {
    this.currentIndex = index
    this.currentId = this.list[index].sceneryId
    this.loadDetail()
    this.recordList = []
    this.recordPage = 1
    this.$emit('setMarkersSelect', [{ id: this.currentId }])
  }

  // 右侧菜单切换
  handleClick (tab: { name: string }): void {
    if (tab.name === 'second' && this.recordPage === 1) {
      this.loadRecord()
    }
  }
}
