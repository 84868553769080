

import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class RightModal extends Vue {
  @Prop() readonly value!: boolean;
  created () {
    // do something
  }
}
