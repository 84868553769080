
import { Vue, Component, Prop } from 'vue-property-decorator'
import Seedling from './Seedling.vue'
import { MapPointDetail } from '@/types/workbenchResource.d'

@Component({
  components: {
    Seedling
  }
})
export default class FamousTree extends Vue {
  @Prop() readonly projectId!: string

  // 添加坐标, hasMarkerClick是否含有点击事件，true 是， false 否
  drawMarkers (arr: Array<{ type: string; list: Array<MapPointDetail> }>, hasMarkerClick = true) {
    this.$emit('drawMarkers', arr, hasMarkerClick)
  }

  // 选中高亮 list为选中的id数组对象
  setMarkersSelect (list: Array<{ id: string }>) {
    this.$emit('setMarkersSelect', list)
  }
}
