
import { Component, Vue } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import iconList from '@/views/workbench/common/components/iconList'
import { MapPointDetail, MapInfo } from '@/types/workbenchResource'

@Component({
  name: 'MapView'
})
export default class MapView extends Vue {
  private isMapLoad = false
  private mapViewMode: '3D' | '2D' = '3D'
  private mapNum = 0
  private map: AMap.Map | undefined = undefined
  private markerBg: AMap.Marker | undefined = undefined
  private markerList: Array<AMap.Marker> = []
  // 总览，前区，花谷
  private mapInfoList: Array<MapInfo> = [{
    longitude: 113.595511,
    latitude: 23.43747,
    zoom: 16.398,
    pitch: 56,
    rotation: -5.5,
    mapBg: require('@/assets/img/workbench/tianrenshanshui/map_bg0.jpg')
  }, {
    longitude: 113.588612,
    latitude: 23.435772,
    zoom: 17.25,
    pitch: 40,
    rotation: 78,
    mapBg: require('@/assets/img/workbench/tianrenshanshui/map_bg1.jpg')
  }, {
    longitude: 113.599045,
    latitude: 23.43397,
    zoom: 17.78,
    pitch: 47,
    rotation: -44,
    mapBg: require('@/assets/img/workbench/tianrenshanshui/map_bg2.jpg')
  }]

  created (): void {
    this.loadMap().then(() => {
      this.isMapLoad = true
    })
  }

  destroyed (): void {
    if (this.map) {
      this.map.destroy()
    }
  }

  // 渲染地图
  loadMap () {
    return AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    })
  }

  // 地图添加
  drawMap () {
    if (this.mapNum < this.mapInfoList.length) {
      this.map && this.map.destroy()
      const mapInfo = this.mapInfoList[this.mapNum]
      const point = new AMap.LngLat(mapInfo.longitude, mapInfo.latitude)
      const map = new AMap.Map('map', {
        animateEnable: false,
        dragEnable: this.mapViewMode === '2D',
        zoomEnable: this.mapViewMode === '2D',
        pitchEnable: false,
        rotateEnable: false,
        keyboardEnable: false,
        zoom: mapInfo.zoom,
        rotation: mapInfo.rotation,
        pitch: mapInfo.pitch,
        viewMode: this.mapViewMode,
        center: point,
        layers: [
          // 卫星
          new AMap.TileLayer.Satellite(),
          // 路网
          new AMap.TileLayer.RoadNet()
        ]
      })
      map.on('click', () => {
        this.$event.$emit('mapClick')
      })
      this.map = map
      if (this.mapViewMode === '3D') {
        this.markerBg = new AMap.Marker({
          position: point,
          offset: new AMap.Pixel(-960, -540),
          icon: mapInfo.mapBg,
          draggable: false,
          zIndex: 2
        })
        this.markerBg.on('click', () => {
          this.$event.$emit('mapClick')
        })
        ;(this.map as AMap.Map).add(this.markerBg as AMap.Marker)
      }
      if (this.markerList) {
        ;(this.map as AMap.Map).add(this.markerList)
      }
    }
  }

  // 地图切换
  changeMap (mapNum: number) {
    this.mapNum = mapNum
    this.setMapCenter(mapNum, false)
  }

  // 地图viewMode切换
  changeMapViewMode (viewMode: '3D' | '2D') {
    if (this.mapViewMode !== viewMode) {
      this.mapViewMode = viewMode
      this.setMapCenter(this.mapNum, false)
    }
  }

  // 判断地图组件是否加载完成,mapNum: 0 项目区域；1 前区；2 花谷
  setMapCenter (mapNum: number, isProjectChange: boolean): void {
    if (isProjectChange) {
      this.mapNum = 0
    }
    if (this.isMapLoad) {
      this.drawMap()
    } else {
      this.loadMap().then(() => {
        this.isMapLoad = true
        this.drawMap()
      })
    }
  }

  // 获取图片
  getIcon (type: string) {
    const iconIndex = iconList.findIndex(item => item.type === type)
    return iconIndex >= 0 ? iconList[iconIndex].icon : iconList[0].icon
  }

  removeAllMarker () {
    const map = this.map as AMap.Map
    const markers = map.getAllOverlays('marker')
    markers && map.remove(markers)
    if (this.mapViewMode === '3D') {
      map.add(this.markerBg as AMap.Marker)
    }
  }

  // 地图打点
  drawMarkers (arr: Array<{ type: string; list: Array<MapPointDetail> }>) {
    const markerArr: AMap.Marker<any>[] = []
    arr.forEach(item => {
      const icon = this.getIcon(item.type)
      if (item.list.length > 0) {
        // 循坏将设备marker添加到地图
        item.list.forEach((point: MapPointDetail) => {
          const marker = this.getMarker(item.type, icon, point)
          if (marker) {
            markerArr.push(marker)
          }
        })
      }
    })
    this.markerList = markerArr
    this.map && this.map.add(markerArr)
  }

  // 选中高亮 list为选中的id数组对象, 如果list为空数组，则表示去掉所有高亮点
  setMarkersSelect (list: Array<{ id: string }>) {
    const markers: Array<AMap.Marker> = this.map ? (this.map.getAllOverlays('marker') as Array<AMap.Marker>) : []
    markers.forEach(marker => {
      const data = marker.getExtData()
      const index = list.findIndex(item => item.id === data.id)
      // isSelect标记是否是高亮图标, 去掉当前高亮
      if (data.isSelect && index < 0) {
        const icon = this.getIcon(data.type)
        marker.setIcon(new AMap.Icon({
          // 图标的取图地址
          image: icon,
          imageSize: new AMap.Size(36, 48)
        }))
        marker.setOffset(new AMap.Pixel(-18, -48))
      } else if (index >= 0) {
        const icon = this.getIcon(data.type + 'Select')
        marker.setIcon(new AMap.Icon({
          // 图标的取图地址
          image: icon,
          imageSize: new AMap.Size(48, 73)
        }))
        marker.setOffset(new AMap.Pixel(-24, -64))
        marker.setExtData({ ...data, isSelect: true })
      }
    })
  }

  getMarker (type: string, icon: string, point: MapPointDetail): AMap.Marker | null {
    let marker = null
    if (point.longitude && point.latitude) {
      const id = point.id || point.seedlingId || point.buildId || point.serviceId || point.sceneryId
      marker = new AMap.Marker({
        icon: new AMap.Icon({
          // 图标的取图地址
          image: icon,
          imageSize: new AMap.Size(36, 48)
        }),
        zIndex: 3,
        offset: new AMap.Pixel(-18, -48),
        position: [Number(point.longitude), Number(point.latitude)],
        extData: { type: type, id: id }
      })
      marker.on('click', this.markerClick)
    }
    return marker
  }

  // 点击地图点标记
  markerClick (e: any): void {
    console.log('markerClick:', e.target)
    this.setMarkersSelect([])
    const data = e.target.getExtData()
    const icon = this.getIcon(data.type + 'Select')
    e.target.setIcon(new AMap.Icon({
      // 图标的取图地址
      image: icon,
      imageSize: new AMap.Size(48, 73)
    }))
    e.target.setOffset(new AMap.Pixel(-24, -64))
    e.target.setExtData({ ...data, isSelect: true })
    this.$event.$emit('markerClick', data.id)
  }
}
