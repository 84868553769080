export default [
  [{
    icon: 'iconicon_left_putongmiaomu',
    name: '普通苗木',
    url: 'Seedling'
  }, {
    icon: 'iconicon_left_gushumingmu',
    name: '古树名木',
    url: 'FamousTree'
  }, {
    icon: 'iconicon_left_jianzhusheshi',
    name: '建筑设施',
    url: 'Building'
  }, {
    icon: 'iconicon_left_fuwusheshi',
    name: '服务设施',
    url: 'Service'
  }, {
    icon: 'iconicon_left_jingguanjingdian',
    name: '景观景点',
    url: 'Scenery'
  }]
]
