import { render, staticRenderFns } from "./Service.vue?vue&type=template&id=f8a5b416&scoped=true"
import script from "./Service.vue?vue&type=script&lang=ts"
export * from "./Service.vue?vue&type=script&lang=ts"
import style0 from "./Service.vue?vue&type=style&index=0&id=f8a5b416&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8a5b416",
  null
  
)

export default component.exports