

import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class LeftModal extends Vue {
  @Prop() readonly title!: string;
  @Prop() readonly width!: number;
  created () {
    // do something
  }
}
